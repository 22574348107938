import {
    ToastContainer,
    toast,
    // cssTransition,
  } from "react-toastify";
  import { APIURL } from "../ApiServices/Axios/Axios";
  // import "animate.css/animate.min.css";
  import "react-toastify/dist/ReactToastify.css";
  // import { addWishList, deleteWishList } from "../ApiServices/Apifun";
  // Get an item from local storage
  export const getLocalStorageItem = (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error getting ${key} from local storage: ${error}`);
      return null;
    }
  };
  
  // Set an item in local storage
  export const setLocalStorageItem = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting ${key} in local storage: ${error}`);
    }
  };
  
  // Remove an item from local storage
  export const removeLocalStorageItem = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key} from local storage: ${error}`);
    }
  };
  
  //antd custom message function
  // export const messageConfiguration = (type, message, duration) => {
  //   return {
  //     type: type,
  //     content: message,
  //     style: { marginTop: "80px" },
  //     duration: duration,
  //   };
  // };
  
  // custom react-toastify
  export const dispatchtoast = (message, type) => {
    if (type) {
      return toast.error(message);
    }
    return toast.success(message);
  };
  
  // dynamic function to catch all errors and show on react toastify
  export const showError = (error) => {
    if (typeof error === "object") {
      Object.entries(error).map(([key, value]) => {
        const errmessage = `${key}: ${value}`;
        return dispatchtoast(errmessage, true);
      });
    } else {
      return dispatchtoast(error, true);
    }
  };
  
  // patterns for pin,aadhar,pan,email,mobile,gst
  export const pincodePattern = /^[1-9][0-9]{5}$/; // Regular expression for valid PIN code format
  // export const aadharPattern = /^\d{12}$/; // Regular expression for valid Aadhaar card number format
  // export const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/; // Regular expression for PAN number format
  export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Regular expression for email format
  export const mobilePattern = /^[6-9]\d{9}$/; // Regular expression for valid mobile number format
  export const gstPattern =
    /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // GST number pattern
  // export const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  // export const pancardValidation = (text) => {
  //   let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  //   if (regex.test(text)) {
  //     return true;
  //   }
  //   return false;
  // };
  // export const validateEmail = (email) => {
  //   const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  //   return emailRegex.test(email);
  // };
  // export const validateAadharNumber = (number) => {
  //   const aadharRegex = /^\d{12}$/;
  //   if (aadharRegex.test(number)) {
  //     return true;
  //   }
  //   return false;
  // };
  
  // function to convert rupee in number to rupeee in words
  // export const numberToWords = (num) => {
  //   const singleDigitWords = [
  //     "Zero",
  //     "One",
  //     "Two",
  //     "Three",
  //     "Four",
  //     "Five",
  //     "Six",
  //     "Seven",
  //     "Eight",
  //     "Nine",
  //   ];
  //   const twoDigitWords = [
  //     "Ten",
  //     "Eleven",
  //     "Twelve",
  //     "Thirteen",
  //     "Fourteen",
  //     "Fifteen",
  //     "Sixteen",
  //     "Seventeen",
  //     "Eighteen",
  //     "Nineteen",
  //   ];
  //   const tensWords = [
  //     "",
  //     "",
  //     "Twenty",
  //     "Thirty",
  //     "Forty",
  //     "Fifty",
  //     "Sixty",
  //     "Seventy",
  //     "Eighty",
  //     "Ninety",
  //   ];
  
  //   if (num < 0 || num >= 10000) {
  //     return "Number out of range";
  //   }
  
  //   if (num < 10) {
  //     return singleDigitWords[num] + " Only";
  //   }
  
  //   if (num >= 10 && num < 20) {
  //     return twoDigitWords[num - 10] + " Only";
  //   }
  
  //   if (num >= 20 && num < 100) {
  //     const tens = Math.floor(num / 10);
  //     const ones = num % 10;
  //     return (
  //       tensWords[tens] + (ones ? " " + singleDigitWords[ones] : "") + " Only"
  //     );
  //   }
  
  //   if (num >= 100 && num < 1000) {
  //     const hundreds = Math.floor(num / 100);
  //     const remaining = num % 100;
  //     return (
  //       singleDigitWords[hundreds] +
  //       " Hundred" +
  //       (remaining ? " " + numberToWords(remaining) : "")
  //     );
  //   }
  
  //   if (num >= 1000 && num < 10000) {
  //     const thousands = Math.floor(num / 1000);
  //     const remaining = num % 1000;
  //     return (
  //       singleDigitWords[thousands] +
  //       " Thousand" +
  //       (remaining ? " " + numberToWords(remaining) : "")
  //     );
  //   }
  // };
  // const bounce = cssTransition({
  //   enter: "animate__animated animate__slideInRight",
  //   exit: "animate__animated animate__animate__slideOutRight",
  //   appendPosition: false,
  //   collapse: true,
  //   collapseDuration: 400,
  // });
  // function to define react-toastify
  export const ToastContainerMsg = () => {
    return (
      <ToastContainer
        style={{
          fontSize: "12px",
          color: "black",
          textTransform: "capitalize",
        }}
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        rtl={false}
        theme="light"
      />
    );
  };
  
  // LIST of all the services provided by B2B
  
  export const createImageUrl = (imageName, type = "product") => {
    return `${APIURL}/uploads/${type}/${imageName}`;
  };
  // export const getprice = (baseamount, optionarr = {}) => {
  //   let data = Object?.keys(optionarr).map((key) => {
  //     return {
  //       type: optionarr[key].type,
  //       price: optionarr[key].price,
  //     };
  //   });
  //   let total = baseamount;
  //   for (let index = 0; index < data.length; index++) {
  //     if (data[index].type === "inc") {
  //       total = total + data[index].price;
  //     } else if (data[index].type === "dec") {
  //       total = total - data[index].price;
  //     }
  //   }
  //   return total;
  // };
  
  // function to enable search in antd select by label
  export const filterOption = (input, option) =>
    option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;
  
  export const trimString = (string, limit = 25) => {
    const trim = string
      ? string.length > limit
        ? string.substring(0, limit) + "..."
        : string
      : "---";
    return trim;
  };
  
  // export const handleAddToFavourite = (data, apiCall = () => {}) => {
  //   addWishList({
  //     record_id: data,
  //   })
  //     .then((res) => {
  //       if (res.status) {
  //         apiCall();
  //         dispatchtoast(res.message);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  // export const handleRemoveFromFavourite = (data, apiCall = () => {}) => {
  //   deleteWishList({
  //     record_id: [data],
  //   })
  //     .then((res) => {
  //       if (res.status) {
  //         apiCall();
  //         dispatchtoast(res.message);
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };
  
  // export const ManageWishlistCount = (data, type) => {
  //   let temp = { ...data };
  //   if (type === "add") {
  //     temp = { ...temp, total_wishlist_item: temp.total_wishlist_item - 1 };
  //     return temp
  //   } else {
  //     temp = { ...temp, total_wishlist_item: temp.total_wishlist_item - 1 };
  //     return temp
  
  //   }
  // };
  
  // export const priceScale=[
  //   {
  //     label:"Below Rs.500",
  //     value:{min:1,max:500}
  //   },
  //   {
  //     label:"Rs.501-1000",
  //     value:{min:501,max:1000}
  //   }, {
  //     label:"Rs.1001-1500",
  //     value:{min:1001,max:1500}
  //   }, {
  //     label:"Rs.1501-2000",
  //     value:{min:1501,max:2000}
  //   }, {
  //     label:"Rs.2001-2500",
  //     value:{min:2001,max:2500}
  //   },
  // ]